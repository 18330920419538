export const socialsData = {
    github: 'https://github.com/sovinbirla',
    facebook: 'https://www.facebook.com/profile.php?id=100008394522359',
    linkedIn: 'https://www.linkedin.com/in/sovin-birla-421416148/',
    instagram: 'https://www.instagram.com/sovin_birla/',
    twitter: 'https://twitter.com/SovinBirla',
    spotify: 'https://open.spotify.com/user/32tspal6zzctn2sl91ssm790t',
    codepen: '',
    reddit: '',
    blogger: '',
    medium: '',
    stackOverflow: '',
    gitlab: '',
    youtube: ''
}